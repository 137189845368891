
import { reactive, defineComponent, onMounted, toRefs } from 'vue';
import { getArticles } from '@/apis/articles';
import { ArticleModel } from '@/model/articleModel';
import { formatJson } from '@/utils';
import { exportJson2Excel } from '@/utils/excel';
import FilenameOption from './components/FilenameOption.vue';
import AutoWidthOption from './components/AutoWidthOption.vue';
import BookTypeOption from './components/BookTypeOption.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    AutoWidthOption,
    FilenameOption,
    BookTypeOption
  },
  setup() {
    const { t } = useI18n();
    const dataMap = reactive({
      list: Array<ArticleModel>(),
      listLoading: true,
      downloadLoading: false,
      filename: '',
      autoWidth: true,
      bookType: 'xlsx'

    });

    const changeinput = (value: any) => {
      dataMap.bookType = value;
    };

    const fetchData = async() => {
      dataMap.listLoading = true;
      const data = await getArticles({ /* Your params here */ });
      dataMap.list = data?.data.items ?? [];
      // Just to simulate the time of the request
      setTimeout(() => {
        dataMap.listLoading = false;
      }, 0.5 * 1000);
    };
    const handleDownload = () => {
      dataMap.downloadLoading = true;
      const tHeader = ['Id', 'Title', 'Author', 'Readings', 'Date'];
      const filterVal = ['id', 'title', 'author', 'pageviews', 'timestamp'];
      const list = dataMap.list;
      const data = formatJson(filterVal, list);
      exportJson2Excel(tHeader, data, dataMap.filename !== '' ? dataMap.filename : undefined, undefined, undefined, dataMap.autoWidth, dataMap.bookType);
      dataMap.downloadLoading = false;
    };
    onMounted(() => {
      fetchData();
    });
    return { t, ...toRefs(dataMap), handleDownload, fetchData, changeinput };
  }
});
