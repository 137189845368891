
import { reactive, defineComponent, computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  emits: ['input'],
  setup(props, ctx) {
    const dataMap = reactive({

    });
    const { t } = useI18n();
    const filename = computed({
      get: () => {
        return props.value;
      },

      set: (value) => {
        ctx.emit('input', value);
      }
    });

    return { t, ...toRefs(dataMap), filename };
  }
});
